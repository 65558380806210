<template>
  <div class="space-y-3 p-2 bg-white shadow-sm w-full">
    <div class="flex items-center space-x-2 w-full">
      <div>
        <template v-if="contact.is_a_business_contact || indexPosition === 0">
          <icon-base
            v-if="isContactUnknown"
            data-cy="business-contact-icon"
            :icon="IconCompanyAddress"
            width="45"
            height="45"
            :icon-color="UTIL_COLORS.WHITE"
            class="rounded-full bg-gray-300"
            view-box="-4 -4 24 24"
          />
          <icon-base
            v-else
            data-cy="business-contact-icon"
            :icon="IconCompanyStar"
            width="46"
            height="46"
          />
        </template>
        <template v-else>
          <icon-base
            v-if="isContactUnknown"
            height="45"
            width="45"
            view-box="0 0 48 48"
            icon-color="#d1d5db"
            icon="profile"
          />
          <lf-avatar v-else :username="formatName(contact)" :size="45" />
        </template>
      </div>
      <div class="flex flex-col w-full">
        <span
          class="text-sm font-bold"
          :class="{ 'text-headline': !isContactUnknown }"
        >
          {{ formatName(contact) }}
        </span>
        <div class="flex contact-block w-full justify-between">
          <div class="flex flex-col" data-cy="contact-details">
            <div
              v-if="contact.email_address"
              v-tooltip="contact.email_address"
              class="truncate max-w-[140px] cursor-pointer"
            >
              {{ contact.email_address }}
            </div>
            <span
              v-if="contact.is_a_business_contact || indexPosition === 0"
              class="text-xs text-label"
            >
              {{ $t("ACTIVITY.CONTACTS.BUSINESS") }}
            </span>
            <span v-else class="text-xs text-label">
              {{ $t("ACTIVITY.CONTACTS.OWNER", { number: indexPosition }) }}
            </span>
          </div>
          <copy-to-clipboard-button
            v-if="contact.email_address"
            class="copy-button"
            :value="contact.email_address"
          />
        </div>
      </div>
    </div>
    <div v-if="contactTelephones.length">
      <telephone-field
        v-for="(telephone, index) in contactTelephones"
        :key="index"
        class="cursor-pointer"
        :class="
          activeTelephone === telephone
            ? 'bg-primary-background-active'
            : 'bg-white'
        "
        data-cy="contact-list-item"
        :telephone="telephone"
        :is-active="activeTelephone === telephone"
        :is-default="defaultTelephones?.[id] === telephone"
        :is-primary="index === 0"
        @click="telephone && setTelephoneForApplication(id, telephone)"
      />
    </div>
    <div v-else class="px-1 space-x-2">
      <icon-base icon="telephone" />
      <span>{{ $t("ACTIVITY.CONTACTS.NO_CONTACT_INFO") }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useContacts } from "@/hooks/contacts";
import TelephoneField from "@/views/deals/components/TelephoneField.vue";
import IconCompanyStar from "@/components/icons/IconCompanyStar.vue";
import { formatUserFullName } from "@/helpers/formatting";
import type { PropType } from "vue";
import type { ContactTelephones } from "@/models/communicationLogs";
import isEmpty from "lodash/isEmpty";
import CopyToClipboardButton from "@/components/CopyToClipboardButton.vue";
import IconBase from "@/components/ui/IconBase.vue";
import IconCompanyAddress from "@/components/icons/IconCompanyAddress.vue";
import { useI18n } from "vue-i18n";
import { UTIL_COLORS } from "@/helpers/constants";

const props = defineProps({
  contact: {
    type: Object as PropType<ContactTelephones>,
    required: true
  },
  indexPosition: {
    type: Number,
    required: true
  },
  id: {
    required: true,
    type: String
  }
});

const { t } = useI18n();
const { setTelephoneForApplication, selectedTelephones, defaultTelephones } =
  useContacts();

const contactTelephones = computed(() => {
  const allTelephones = [
    props.contact.telephone,
    ...(props.contact.additional_telephones || []),
    ...(props.contact.telephones || [])
  ];
  return allTelephones.filter(Boolean);
});

const activeTelephone = computed(() =>
  isEmpty(selectedTelephones.value[props.id])
    ? defaultTelephones.value[props.id]
    : selectedTelephones.value[props.id]
);

const isContactUnknown = computed(
  () => formatName(props.contact)?.includes(t("COMMON.UNKNOWN"))
);

const formatName = (contact: ContactTelephones) =>
  contact.is_a_business_contact
    ? contact.business_legal_name
    : formatUserFullName(contact);
</script>

<style scoped>
.copy-button {
  @apply invisible opacity-0 hover:bg-gray-200 relative -top-2 p-2;
}

.contact-block:hover .copy-button {
  @apply visible opacity-100;
}
</style>
